import React from "react";
import Info from "../../components/Info";
import Title from "../../components/Title";
import Jobs from "../../components/Jobs";
import {
    Container,
    Wrapper,
    MainTitleWrapper,
    JobContainer,
    JobWrapper,
    JobContent,
    JobsTitleWrapper,
    TitleWrapper,
    AllJobsSection,
    ApplyButton,
} from "./styled";

const CareerDetail = ({ info, html, jobs, emptyData }) => {
    const { title, tags, description, link } = info;
    let finalLink = undefined

    if (link) {
        finalLink = link
    } else {
        finalLink = `mailto:${process.env.METACRAFT_CAREERS_EMAIL || 'careers@meta-craft.com'}?subject=${encodeURIComponent(title)}`
    }

    return (
        <Container>
            <Wrapper>
                <MainTitleWrapper>
                    <Info
                        key={`info`}
                        title={{ label: title }}
                        services={tags}
                        text={{ label: description }}
                        width={60}
                        mainTitle
                    />
                </MainTitleWrapper>
            </Wrapper>
            <JobContainer>
                <JobWrapper>
                    <JobContent dangerouslySetInnerHTML={{ __html: html }} />
                    <ApplyButton to={finalLink} hoverStyle="none" weight="bold">Apply Now</ApplyButton>
                </JobWrapper>
            </JobContainer>
            <AllJobsSection>
                <JobsTitleWrapper>
                    <Title
                        key={'title-jobs'}
                        title={[{ label: 'Open Positions' }]}
                        element={TitleWrapper}
                        inline={true}
                        justify={"center"}
                    />
                </JobsTitleWrapper>
                <Jobs jobs={jobs} emptyData={emptyData} />
            </AllJobsSection>
        </Container>
    )
}

export default CareerDetail
