import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CareerDetail from "../containers/careerDetail"
import get from "lodash/get"

const Render = ({ data }) => {
    const markdown = data.markdownRemark
    // Get Jobs
    const jobs = get(data, "allMarkdownRemark.edges", []).map(
        ({ node }) => node
    )
    const empty = get(data, "careersYaml.empty", [])
    return (
        <Layout>
            <SEO title={markdown.frontmatter.title} />
            <CareerDetail
                info={markdown.frontmatter}
                html={markdown.html}
                jobs={jobs}
                emptyData={empty}
            />
        </Layout>
    )
}
export default Render

export const query = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                location
                publication_date
                tags
                title
                description
                link
            }
        }

        allMarkdownRemark(
            filter: { fields: { group: { eq: "jobs" } }, id: { ne: $id } }
            sort: { order: DESC, fields: frontmatter___publication_date }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        tagline
                        tags
                        publication_date
                    }
                    fields {
                        slug
                    }
                }
            }
        }

        careersYaml {
            empty {
                text
                button {
                    link
                    label
                }
            }
        }
    }
`
