import React from "react";
import Link from "../../components/UI/Link";
import styled from "@emotion/styled";
import { Colors, Layout, media } from "@theme";
import { H2 } from "../../components/UI/Typography";

export const Container = styled.div``;
export const Wrapper = styled(Layout.Wrapper)``;
export const MainTitleWrapper = styled(Layout.MainTitleWrapper)`
    padding-top: 130px;

    ${media.small}{
        padding-top: 60px;
    }
`;

export const JobContainer = styled.div`
    background-color: ${Colors.jobBackground};
    padding: 60px 0;
    padding-top: 30px;
`;

export const ApplyButton = styled(Link)`
    margin-top: 30px;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #F96755;
    border-radius: 4px;
    background-color: #F96755;
    color: white;
    text-transform: uppercase;
    transition: opacity 400ms ease;
    white-space: nowrap;
    width: 100%;
    max-width: 350px;
    text-align: center;

    ${media.hover} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const JobWrapper = styled(Layout.Wrapper)``;

export const JobContent = styled.div`
    h2 {
        margin: 3rem 0;
        font-size: 34px;
        font-weight: 600;
    }

    h3 {
        margin: 2rem 0;
    }

    p {
        line-height: 1.8;
    }

    ul {
        padding-inline-start: 20px;
    }

    li {
        line-height: 1.8;
        margin-bottom: 1rem;
    }
`;

export const AllJobsSection = styled.div`
    padding: 20px 0 80px 0;
`;

export const TitleWrapper = ({ children }) => <H2 weight="bold">{children}</H2>;

export const JobsTitleWrapper = styled.div`
    margin: 8rem auto;
`;